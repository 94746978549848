import { ModalContent, ModalFooter, ModalHeader } from 'common/components/Modal';
import I18n from 'common/i18n';
import formatString from 'common/js_utils/formatString';
import { InputSchema, OutputColumn, OutputSchema, WithTransform } from 'common/types/dsmapiSchemas';
import { Expr } from 'common/types/soql';
import { ColumnFormat } from 'common/types/viewColumn';
import { ColumnLike } from 'datasetManagementUI/lib/columnLike';
import { Entities } from 'datasetManagementUI/lib/types';
import _ from 'lodash';
import React, { Component } from 'react';
import LocationColumnFormat from './LocationColumnFormat';
import FormatPreview from './FormatPreview';
import TextColumnFormat from 'common/FormatColumn/TextColumnFormat';
import BoolColumnFormat from 'common/FormatColumn/BoolColumnFormat';
import DatetimeColumnFormat from 'common/FormatColumn/DatetimeColumnFormat';
import NumberColumnFormat from 'common/FormatColumn/NumberColumnFormat';
const t = (k: string) => I18n.t(k, { scope: 'dataset_management_ui' });

const isOutputColumn = (
  c: ColumnLike<never> | (OutputColumn & WithTransform)
): c is OutputColumn & WithTransform => _.has(c, 'transform');

interface Props {
  // These props are needed for default view previewing.
  inputSchema?: InputSchema;
  outputSchema?: OutputSchema;

  outputColumn: ColumnLike<never> | (OutputColumn & WithTransform);
  onDismiss: () => void;
  onSave: (format: ColumnFormat, expr: Expr | null) => void;
  saveText?: string;
  entities: Entities;
}

interface State {
  format: ColumnFormat;
  ast: Expr | null;
}

class FormatColumn extends Component<Props, State> {
  state = {
    format: {},
    ast: null
  };

  UNSAFE_componentWillMount() {
    const newState: any = {};
    newState.format = this.props.outputColumn.format || {}; // hack until dsmapi fills in true defaults,
    if (isOutputColumn(this.props.outputColumn)) {
      newState.ast = this.props.outputColumn.transform.parsed_expr;
    }

    this.setState(newState);
  }

  componentDidMount() {
    document.addEventListener('keyup', this.captureEscKey, true);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.captureEscKey, true);
  }

  onUpdateFormat = (change: ColumnFormat, baseChange?: ColumnFormat) => {
    this.setState({
      format: _.omitBy(
        {
          ...(baseChange || {}),
          ...this.state.format,
          ...change
        },
        _.isUndefined
      )
    });
  };

  onUpdateAST = (newAST: Expr) => {
    this.setState({
      ast: newAST
    });
  };

  onRemoveFormat = (key: string) => {
    this.setState({
      format: _.omit(this.state.format, [key])
    });
  };

  captureEscKey = (event: KeyboardEvent) => {
    const { onDismiss } = this.props;
    const escapeKeyCode = 27;
    if (event.keyCode === escapeKeyCode) {
      // we need to stop the event from propagating so that the parent modal doesn't also close
      event.stopPropagation();
      onDismiss();
    }
  };

  getFormatter(column: ColumnLike<never> | (OutputColumn & WithTransform), format: ColumnFormat) {
    const outputSoqlType = isOutputColumn(column)
      ? column.transform.output_soql_type
      : column.output_soql_type;
    switch (outputSoqlType) {
      case 'number':
        return (
          <NumberColumnFormat
            format={format}
            onRemoveFormat={this.onRemoveFormat}
            onUpdateFormat={this.onUpdateFormat}
            onUpdateAST={this.onUpdateAST}
            ast={this.state.ast}
            formatIndex={0}
            defaultPrecision="standard"
            defaultAlignment="left"
            includeFormTag
            showAlignment
          />
        );
      case 'calendar_date':
        return (
          <DatetimeColumnFormat
            format={format}
            onUpdateFormat={this.onUpdateFormat}
            onRemoveFormat={this.onRemoveFormat}
            showAlignment
            defaultAlignment="left"
            includeFormTag
          />
        );
      case 'date':
        return (
          <DatetimeColumnFormat
            format={format}
            onUpdateFormat={this.onUpdateFormat}
            onRemoveFormat={this.onRemoveFormat}
            showAlignment
            defaultAlignment="left"
            includeFormTag
          />
        );
      case 'location':
        return (
          <LocationColumnFormat
            format={format}
            onUpdateFormat={this.onUpdateFormat}
            defaultAlignment="left"
          />
        );
      case 'checkbox':
      case 'boolean':
        return (
          <BoolColumnFormat
            format={format}
            onChange={this.onUpdateFormat}
            showAlignment
            defaultAlignment="left"
            includeFormTag
          />
        );
      default:
        // default is to allow anything to formatted
        // with text formatting rules
        return (
          <TextColumnFormat
            format={format}
            showRenderAs={outputSoqlType === 'text'}
            onUpdateFormat={this.onUpdateFormat}
            showAlignment
            includeFormTag
            defaultAlignment="left"
            defaultRender="normal"
          />
        );
    }
  }

  render() {
    const { onDismiss, onSave, outputColumn, saveText } = this.props;
    const { format, ast }: State = this.state;
    const headerProps = {
      title: formatString(t('format_column.title'), { name: outputColumn.display_name }),
      onDismiss
    };

    const formatter = this.getFormatter(outputColumn, format);
    const previewer = this.props.inputSchema && this.props.outputSchema && (
      <div className="previewer">
        <FormatPreview
          entities={this.props.entities}
          inputSchema={this.props.inputSchema!}
          outputSchema={this.props.outputSchema!}
          outputColumn={outputColumn as OutputColumn & WithTransform}
          format={format}
        />
      </div>
    );
    const saveButtonLabel = saveText || t('common.save');

    const outputSoqlType = isOutputColumn(outputColumn)
      ? outputColumn.transform.output_soql_type
      : outputColumn.output_soql_type;

    // In number columns we don't want the user to save with a blank custom group separator, so disable the button until they fix that.
    const isSaveDisabled = outputSoqlType == 'number' && format.groupSeparator === '';

    return (
      <div id="format-column">
        <ModalHeader {...headerProps} />
        <ModalContent>
          <div className="format-content">
            <div className="formatter">{formatter}</div>
            {previewer}
          </div>
        </ModalContent>
        <ModalFooter>
          <button className="btn btn-default" onClick={onDismiss} data-testid="format-column-cancel-button">
            {t('common.cancel')}
          </button>
          <button
            className="btn btn-primary"
            onClick={() => onSave(format, ast)}
            disabled={isSaveDisabled}
            data-testid="format-column-save-button"
          >
            {saveButtonLabel}
          </button>
        </ModalFooter>
      </div>
    );
  }
}

export default FormatColumn;
