import uuid from 'uuid';
import _ from 'lodash';
import { Dispatch } from 'redux';
import { addNotification } from 'datasetManagementUI/reduxStuff/actions/notifications';
import {
  apiCallStarted,
  apiCallSucceeded,
  apiCallFailed
} from 'datasetManagementUI/reduxStuff/actions/apiCalls';
import * as dsmapiLinks from 'datasetManagementUI/links/dsmapiLinks';
import { xhrPromise, updateProgress, UPLOAD_FILE, CHUNK_COMPLETED } from 'datasetManagementUI/reduxStuff/actions/uploadFile';
import { socrataFetch, checkStatus, getJson } from 'datasetManagementUI/lib/http';
import { sleep } from 'datasetManagementUI/lib/util';
import {Option, some, none} from 'ts-option';

type BlobCreator = () => Blob;

// types
interface Chunk {
  seqNo: number;
  offset: number;
  getBytes: BlobCreator;
}

interface ByteSource {
  resource: {
    id: number;
  };
}

interface Err {
  key: string;
  status: number;
  details?: string;
}

type PromiseStatus = 'rejected' | 'fulfilled';
interface PromiseResult {
  status: PromiseStatus;
  value?: any;
  reason?: Error;
}

export class CancelledError extends Error {}
class ServerError extends Error {}

function wasCancelled(e: Err): boolean {
  return e.status === 400;
}

function wasServerError(e: Err): boolean {
  return e.status === 500;
}

interface Response {
  status: number;
  response: string;
}

function parseErrorResponse(resp: Response): Option<Err> {
  try {
    const body = JSON.parse(resp.response);
    const key = _.get(body, 'params.failure_details.key') || body.key;
    const err = {
      key: key,
      status: resp.status,
      details: _.get(body, 'params.failure_details.message')
    };
    return some(err);
  } catch (e) {
    return none;
  }
}

interface OnProgress {
  (e: { loaded: number; total: number }): void;
}

// main stuff
export function calcNumChunks(fileSize: number, chunkSize: number): number {
  // Math.max(<chunks>, 1) handles files of zero bytes
  // We want at least one chunk in that case
  return Math.max(Math.ceil(fileSize / chunkSize), 1);
}

export function chunkTo(chunkNum: number, chunkSize: number): number {
  return chunkFrom(chunkNum, chunkSize) + chunkSize;
}

export function chunkFrom(chunkNum: number, chunkSize: number): number {
  return chunkNum * chunkSize;
}

function chunkify(file: File, chunkSize: number): Chunk[] {
  const numChunks = calcNumChunks(file.size, chunkSize);
  const blobs: BlobCreator[] = [];

  for (let i = 0; i < numChunks; i++) {
    blobs.push(() => file.slice(chunkFrom(i, chunkSize), chunkTo(i, chunkSize)));
  }

  return blobs.map((getBytes, idx) => ({
    seqNo: idx,
    offset: chunkFrom(idx, chunkSize),
    getBytes
  }));
}

async function uploadChunk(sourceId: number, chunk: Chunk, onProgress: OnProgress, backoff = 0): Promise<void> {
  await sleep(backoff);
  const { seqNo, offset, getBytes } = chunk;
  const bytes = getBytes();

  return xhrPromise('POST', dsmapiLinks.chunkBytes(sourceId, seqNo, offset), bytes, onProgress)
    .then((resp: any) => {
      // xhrPromise doesn't call onProgress for chunks with no bytes, so call it here
      // to keep update the progress bar notification
      if (bytes.size === 0) {
        onProgress({ loaded: 0, total: 0});
      }
      return resp;
    })
    .catch((e: { response: { status: number; response: string } }) => {
      // We don't want to retry for certain errors, so for those we throw
      // and stop the recursion. Others (like a network blip) are ok to
      // ignore.
      parseErrorResponse(e.response).forEach((err: Err) => {
        if (wasCancelled(err)) {
          throw new CancelledError();
        } else if (wasServerError(err)) {
          throw new ServerError();
        }
      });

      return uploadChunk(sourceId, chunk, onProgress, backoff + 1000);
    });
}

async function uploadChunks(chunks: Chunk[], sourceId: number, onProgress: OnProgress, parallelismHint: number): Promise<void> {
  const chunkGroups = _.chunk(chunks, parallelismHint);
  for (let i = 0; i < chunkGroups.length; i++) {
    // we're polyfilling this with babel and tsc doesn't like it
    // ts-migrate FIXME: no ignore required
    await Promise.allSettled(chunkGroups[i].map(c => uploadChunk(sourceId, c, onProgress))).then((resp: PromiseResult[]) => {
      // uploadChunk should only reject if we want to stop retrying, so if we find any rejected
      // promises in this chunkGroup, throw an error and stop the upload
      const firstError = resp.find(r => r.status === 'rejected');
      if (firstError && firstError.reason) {
        throw firstError.reason;
      }
    });
  }
}

function commitChunks(sourceId: number, totalChunkCount: number, fileSize: number): Promise<ByteSource> {
  return socrataFetch(dsmapiLinks.chunkCommit(sourceId, totalChunkCount - 1, fileSize), {
    method: 'POST',
    body: ''
  }).then(checkStatus)
    .then(getJson);
}

function uploadAndCommitChunks(chunks: Chunk[], sourceId: number, onProgress: OnProgress, file: File, totalChunkCount: number, parallelismHint: number): Promise<ByteSource> {
  return uploadChunks(chunks, sourceId, onProgress, parallelismHint)
    .then(() => {
      return commitChunks(sourceId, totalChunkCount, file.size);
    });
}

export function chunkAndUpload(sourceId: number, file: File, parallelismHint: number, chunksizeHint: number) {
  return (dispatch: Dispatch<any>, getState: any): Promise<ByteSource> => {
    const uploadUpdate = {
      id: sourceId
    };

    const callId = uuid();

    const call = {
      operation: UPLOAD_FILE,
      callParams: uploadUpdate
    };

    dispatch(apiCallStarted(callId, call));

    dispatch(addNotification('source', sourceId));

    const chunks = chunkify(file, chunksizeHint);

    function onProgress({ loaded, total }: { loaded: number; total: number}): void {
      let percent;
      // for files with no bytes, show upload complete rather than divide by zero
      if (file.size === 0) {
        percent = 100;
      } else {
        const chunksCompleted = _.get(getState(), `entities.sources.${sourceId}.chunksCompleted`, 0);
        // usually larger than 100 since the tail chunk is usually < chunk size
        percent = Math.min((loaded + (chunksizeHint * chunksCompleted)) / file.size * 100, 100);
      }

      if (loaded === total) {
        dispatch({ type: CHUNK_COMPLETED, sourceId: sourceId });
      }

      dispatch(updateProgress(sourceId, percent));
    }

    return uploadAndCommitChunks(chunks, sourceId, onProgress, file, chunks.length, parallelismHint)
      .then(resp => {
        dispatch(apiCallSucceeded(callId));
        return resp;
      })
      .catch(async (err) => {
        dispatch(apiCallFailed(callId, err));
        const errBody = await err.response.json();
        if ((errBody.params && errBody.params.missing && Array.isArray(errBody.params.missing))) {
          // If we're here, then we failed to detect an individual chunk upload failure (or the server failed to
          // signal an error) and commited the upload. On commit, the server informs us it's missing some chunks
          // so let's retry them
          const remainingChunks =  chunks.filter(c => errBody.params.missing.includes(c.seqNo));
          return uploadAndCommitChunks(remainingChunks, sourceId, onProgress, file, chunks.length, parallelismHint);
        } else if (err.response) {
          const newErr = err; // lint is mad for mystery reasons otherwise
          newErr.body = errBody;
          newErr.response = err.response;
          throw newErr;
        } else {
          throw err;
        }
      });
  };
}
